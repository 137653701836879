import "./article.css"

function Attak() {
	return <div className={"article"}>
		<h1>Attak</h1>
		<img src={"/projects/Attak/img.png"} alt={""}/>
		<p>
			Tak is a beautiful board game I love to play, inspired by the kingkiller chronicles written by Patrick Rothfuss,
			Its an abstract board game, much like chess or checkers.
		</p>
		<p>
			The community relies heavily on volunteers and open source projects for the infrastructure to play the game online,
			and this is my contribution.
		</p>
		<img src={"/projects/Attak/Mobile.jpg"} alt={""}/>
		<p>
			Attak is a Tak client written in godot, which interfaces with the already existing game servers,
			But giving an entire new interface with new and improved features. It implements the much requested 2D interface,
			as well as dedicated mobile support!
		</p>
		<p>
			A lot of dedication and passion went into this project, if you would like to check it out,
			you can find the source code, as well as prebuilt releases <a href={"https://github.com/The1Rogue/Attak"}>here</a> on github.
		</p>
		<p>
			P.S. if the game sounds interesting to you, come join the <a href={"https://discord.gg/2xEt42X"}>community</a> and play it!
		</p>
	</div>
}

export default Attak;