import './About.css';
import {Link} from "react-router-dom";
import contacts from "../data/contacts.json"

function About() {
	return (
		<div className="about">
			<h1>
				Hi! I'm Fay!
			</h1>
			<p>
				And I love working with the magic of modern technology, if its possible, I can probably make a computer do it!<br/>
				<img src="hello.png" alt=""/>
				In my adventures I have used to a wild variety of systems, and have become fluent in a large variety of languages, like python, java, C#, typescript, and many more!
				If you wish to see some of the things I have worked on and created, you can see those <Link to={"/projects"}>[here]</Link><br/>
			</p>
			<p>
				If you like what you see, you can talk about it with me on these platforms:
				{contacts.map(c => (
					<div className="contact">
						{c.name}: {c.url === "" ? <>{c.tag}</> : <Link to={c.url}>{c.tag}</Link>}
					</div>
				))}
				<a href={"CV_Fay.pdf"}>Resume / CV</a>
			</p>
		</div>
	);
}



export default About;