import './Project.css';

import {Link, useParams} from "react-router-dom";


import LyLua from "./projects/LyLua";
import Languifier from "./projects/Languifier";
import FateBound from "./projects/FateBound";
import SimpleSign from "./projects/SimpleSign";
import LadderBot from "./projects/LadderBot";
import Attak from "./projects/Attak";

const projects = {
    "Attak": <Attak/>,
    "LyLua": <LyLua/>,
    "Languifier": <Languifier/>,
    "FateBound": <FateBound/>,
    "SimpleSign": <SimpleSign/>,
    "Ladder Bot": <LadderBot/>
}

function Project() {
    let { name } = useParams();
    if (name in projects){
        return projects[name]
    } else {
        return (
        <div className="wip">
            Im still working on this project page, but if you want to know more about it, feel free to ask!<br/>
            my contact info is <Link to={"/about"}>here!</Link>
        </div>
    );
    }
}



export default Project;